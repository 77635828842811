<template>
  <div class="card-list">
    <router-link
      v-for="(item, idx) in cardList"
      :key="item.title+idx"
      :to="item.url"
      class="card-list__item"
      :class="[`card-list__item--${isTablet ? 4 : itemsInRow}`]"
    >
      <div
        class="card-list__img-wrap"
      >
        <img
          v-if="item.image"
          :src="item.image"
          :alt="item.imageAlt"
          :title="item.imageDescription"
          :width="isMobile ? 310 : 190"
          :height="isMobile ? 310 : 190"
          loading="lazy"
          class="card-list__img"
        >
        <h3 class="card-list__img-title text-h3" v-html="item.title"></h3>
      </div>
      <span class="card-list__title" v-html="item.title"></span>
    </router-link>
  </div>
</template>

<script>
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'CardList',
  mixins: [ScreenWidthMixin],
  props: {
    cardList: {
      type: Array,
      required: true
    },
    itemsInRow: {
      type: Number,
      default: 6
    }
  }
}
</script>
