<template>
  <div class="home" v-if="topContentData">
    <section class="hero" v-if="topContentData.weOfferSection">
      <BannerCarousel
        :carouselData="topContentData.weOfferSection.carousel"
        :parentKey="topContentData.weOfferSection.parentKey"
      />
    </section>

    <SectionComponent
      class="categories-section"
      v-if="topContentData.categoriesSection &&
        topContentData.categoriesSection.categoriesList &&
        topContentData.categoriesSection.categoriesList.length"
      :title="topContentData.categoriesSection.title"
      :link="topContentData.categoriesSection.link"
    >
      <div class="container">
        <CardList :cardList="topContentData.categoriesSection.categoriesList" />
      </div>
    </SectionComponent>

    <SectionComponent
      class="info-banner-section"
      v-if="topContentData.bannerASection"
    >
      <div class="container">
        <Banner :data="topContentData.bannerASection.banner" :parentKey="topContentData.bannerASection.parentKey" />
      </div>
    </SectionComponent>

    <template v-if="bottomContentData">
      <SectionComponent
        class="products-gallery-section"
        v-if="bottomContentData.newItemsSection"
        :title="bottomContentData.newItemsSection.sectionTitle"
        :link="bottomContentData.newItemsSection.sectionLink"
      >
        <div class="container">
          <ProductsGallery :productsList="bottomContentData.newItemsSection.productsList" />
        </div>
      </SectionComponent>

      <SectionComponent
        class="info-banner-section"
        v-if="bottomContentData.smartSection"
      >
        <div class="container">
          <Banner :data="bottomContentData.smartSection.banner" :parentKey="bottomContentData.smartSection.parentKey" />
        </div>
      </SectionComponent>

      <SectionComponent
        class="page-section product-carousel-section"
        v-if="bottomContentData.saleItemsSection"
        :title="bottomContentData.saleItemsSection.sectionTitle"
        :link="bottomContentData.saleItemsSection.sectionLink"
      >
        <ProductCarousel
          :class="{'container': isMobile}"
          :productsList="bottomContentData.saleItemsSection.productsList"
          :isPriceVisible="true"
          :analyticsData="{referencePage: 'Home Page', enableImpressions: true}"
        />
      </SectionComponent>

      <SectionComponent v-if="bottomContentData.signUpSection">
        <Banner :data="bottomContentData.signUpSection.banner" :parentKey="bottomContentData.signUpSection.parentKey" />
      </SectionComponent>

      <SectionComponent
        class="info-banner-section"
        v-if="bottomContentData.bannerDSection"
      >
        <div class="container">
          <Banner :data="bottomContentData.bannerDSection.banner" :parentKey="bottomContentData.bannerDSection.parentKey" />
        </div>
      </SectionComponent>

      <SectionComponent
        class="brands-section"
        v-if="bottomContentData.brandsSection && bottomContentData.brandsSection.brandsList && bottomContentData.brandsSection.brandsList.length"
        :title="bottomContentData.brandsSection.sectionTitle"
        :link="bottomContentData.brandsSection.sectionLink"
      >
        <div :class="{'container': isMobile}">
          <BrandsCarousel
            class="brands-section__content"
            :brandsList="bottomContentData.brandsSection.brandsList"
          />
        </div>
      </SectionComponent>
    </template>

  </div>
  <loader v-else />
</template>

<script>
import { mapState, mapActions } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import Banner from '@/components/Banner'
import CardList from '@/components/CardList'
import SectionComponent from '@/components/SectionComponent'
import BannerCarousel from '@/components/BannerCarousel'

export default {
  name: 'Home',
  components: {
    Banner,
    CardList,
    SectionComponent,
    BannerCarousel,
    ProductsGallery: () => import('@/components/home-sections/ProductsGallery.vue'),
    ProductCarousel: () => import('@/components/ProductCarousel.vue'),
    BrandsCarousel: () => import('@/components/home-sections/BrandsCarousel')
  },
  mixins: [PageMixin, ScreenWidthMixin],
  methods: {
    ...mapActions('home', ['setTopContentData'])
  },
  computed: {
    ...mapState('home', ['bottomContentData', 'topContentData']),
    pageTitle () {
      return this.topContentData?.meta?.title || this.$route.meta?.title
    },
    metaData () {
      return this.topContentData?.meta
    }
  },
  async created () {
    if (!this.topContentData) await this.setTopContentData()
    this.$nextTick(() => {
      this.setMetaTags()
      this.setPageTitle(this.pageTitle)
    })
  },
  beforeDestroy () {
    this.unsetMetaTags()
  }
}
</script>
